import React from 'react';
import './App.css';
import Logo from './resources/logowhite.svg'
import Image from './resources/DSCF4294.jpg'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <h1 className="Title">CforWPP</h1>
        <h2 className="Subtitle">Center for Wet Poetic Planning</h2> */}
        <img src={Logo} />
      </header>

      <hr />

      

      <main className="Main-content">
        <p className="Description" style={{maxWidth: "600px"}}>
        About water. Solidified, Liquidous, Gaseous.
        <br/> <br/> 
        Curiosity, rediscovery and playfulness.
        <br/><br/>
        As water related climate change risk increases in complexity and intensity, dynamic adaptation strategies will be high on the societal wishlists. The Center for Wet Poetic Planning explores water and itsall its fluid qualities, supporting a humble rediscovery. The Center for Wet Poetic Planning sets out to be an inspiring resource and creative think partner to the planning practice of tomorrow. Using speculative practices for tangible results.
        <br/><br/>
        The project has been developed from within our respective research paths and artistic practice. The start of the project was a fascination with the tidal flats of the Wadden Sea, and the acquiring of a hydrophone. With the hydrophone, we started listening to first the Wadden Sea, then other water bodies, considering what affected them. From little kids peeing, to thermal expansion and to ice caps melting, to tidal pulls of the moon. Fascinated by the tidal pull, so present and so invisible. So one directional it seemed. We considered if the moon even knew the effect it had? Sinking ourselves into the water, we transformed the sound waves of the acoustic recordings from the Wadden Sea in a poetic act, to what we deemed the language of the moon, light waves. Sending these lightwaves back to the moon. Closing the gap, paying playful attention to the many ways is which water works. This first installation was presented at the 2024 POM conference in Aachen, Germany. The second endeavor of the center will be a wandering and watery mapping with students during the Bloom Festival in Søndermarken, Frederiksberg, Denmark. The Center for Wet Poetic Planning will continue to flow towards projects and possibilities guided by a curiosity and an urge to foster a sensitivity to H2O, crossing between the fields of spatial planning and artistic practices. In the fall 2024 The Center for Wet Poetic Planning will move its practice to watery city of Venice for a few months. 

          </p>
          <br/>

          <img src={Image} style={{width: "100%"}} />
          <hr />
          <br/>
          <i>
          We are a transdisciplinary duo working in the intersection of planning, art and research.
        <br/><br/>
        <div style={{maxWidth: "600px"}}>
        <b> Mette Juhl Jessen </b>, PhD fellow with a focus on oceanic perspectives in regional climate adaptation, landscape architect with BA in literature. Editorial board member of Landskab. Interested in watery ontologies, holistic planning and the power of poetry
        <br/><br/>
        <b> Pieter Steyaert </b> is a transdisciplinary artist and researcher. His work is characterized by integrative practices between art and science, focusing on how scientific processes can benefit from artistic insights. He is currently pursuing a PhD, incorporating exploratory methodologies into scientific model development. He is a co-founder of the award-winning international collective SEADS.
        </div>


          </i>
      </main>


      <video autoPlay muted controls style={{width: "100%"}}>
          <source src={`${process.env.PUBLIC_URL}/video.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

      <br/>
          <hr />
          <br/>
      <div style={{width: "100%", textAlign: "center"}}>
      © 2024 - Center for Wet Poetic Planning - All rights reserved

      </div>
    </div>
  );
}

export default App;